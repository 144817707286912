<template>
  <div class="pagination-wrapper" v-if="isShow">
    <div class="total_wrap">
      {{ $t('common.pageTotal') }}
      <span>{{ total }}</span>
      {{ total > 1 ? $t('common.pageTotalRecords') : $t('common.pageTotalRecord') }}
    </div>
    <el-button icon="el-icon-prevPages" @click="prevPages" :disabled="currentNum <= 1"></el-button>
    <el-button icon="el-icon-prevPage" @click="prevPage" :disabled="currentNum <= 1"></el-button>
    <el-select v-model="currentNum" class="select-page" @change="selectPage">
      <el-option :label="item" :value="item" v-for="item in showPage" :key="item"> </el-option>
    </el-select>
    <el-button icon="el-icon-nextPage" @click="nextPage" :disabled="showPage < currentNum + 1"></el-button>
    <el-button icon="el-icon-nextPages" @click="nextPages" :disabled="showPage <= currentNum"></el-button>
  </div>
  <div class="pagination-wrapper" v-else-if="total">
    <div class="total_wrap">
      {{ $t('common.pageTotal') }}
      <span>{{ total }}</span>
      {{ total > 1 ? $t('common.pageTotalRecords') : $t('common.pageTotalRecord') }}
    </div>
  </div>
</template>

<script>
// import PubSub from "pubsub-js";
export default {
  name: 'PaginationBox',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentNum: 1,
      currentPageSize: 0,
    }
  },
  computed: {
    showPage() {
      return Math.ceil(this.total / this.currentPageSize)
    },
    // 是否显示分页，total大于0且超过一页
    isShow() {
      return this.total > 0 && this.showPage > 1
    },
  },
  watch: {
    currentPage(newVal) {
      this.currentNum = newVal
    },
  },
  created() {
    this.currentNum = this.currentPage
    this.currentPageSize = this.pageSize
  },
  methods: {
    // 选择页数
    selectPage(event) {
      this.$emit('current-change', event)
    },
    // 上一页
    prevPage() {
      this.$utils.blurFn()
      let currentPage = this.currentNum - 1
      if (currentPage >= 1) {
        this.currentNum = currentPage
        this.$emit('current-change', this.currentNum)
      }
    },
    // 前5页
    prevPages() {
      this.$utils.blurFn()
      // let currentPage = this.currentNum - 5
      // if (currentPage >= 5) {
      //   this.currentNum = currentPage
      //   this.$emit('current-change', this.currentNum)
      // } else {
      this.currentNum = 1
      this.$emit('current-change', this.currentNum)
      // }
    },
    // 下一页
    nextPage() {
      this.$utils.blurFn()
      let currentPage = this.currentNum + 1
      if (currentPage <= this.showPage) {
        this.currentNum = currentPage
        this.$emit('current-change', this.currentNum)
      }
    },
    // 后5页
    nextPages() {
      this.$utils.blurFn()
      // let currentPage = this.currentNum + 5
      // if (currentPage <= this.showPage) {
      //   this.currentNum = currentPage
      //   this.$emit('current-change', this.currentNum)
      // } else {
      this.currentNum = this.showPage
      this.$emit('current-change', this.currentNum)
      // }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pagination-wrapper {
  margin-top: 25px;
  text-align: center;
  .total_wrap {
    display: inline-flex;
    gap: 10px;
    margin-right: 20px;
    color: $--color-text-primary;
  }
  /deep/ .el-button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0 !important;
    text-align: center;
    &:disabled:hover {
      color: #a3a5b5;
      cursor: not-allowed;
      background-image: none;
      background-color: #ffffff;
      border-color: #f3f3f7;
    }
  }

  .select-page {
    width: 86px;
    margin: 0 10px;

    /deep/ .el-input__inner {
      text-align: center;
    }
  }
}
</style>
