import axios from "axios";
import router from '@/router/'
// import MessageBox from "element-ui/packages/message-box"
// import Loading from 'element-ui/packages/loading';
import { MessageBox, Loading } from 'element-ui';
// import utils from '@/common/utils';
import store from '@/store/index.js'
import i18n from '@/i18n/index.js'
// import { Loading } from 'element-ui';
import PubSub from "pubsub-js";
console.log('process.env.VUE_APP_BASE_URL', process.env)
// 配置axios
let IP = window.location.origin === process.env.VUE_APP_BASE_URL_CN ? process.env.VUE_APP_BASE_URL_CN : process.env.VUE_APP_BASE_URL
if (window.location.origin.indexOf('localhost') > -1) {
	// axios.defaults.baseURL = IP + '/api/proxy';
	// axios.defaults.baseURL = 'https://test.313fm.com:8445/api';
	axios.defaults.baseURL = 'https://test.313fm.com:8449/api';
} else {
	axios.defaults.baseURL = IP + '/api';
}
axios.defaults.timeout = 120000;

let loadingFuncs = []

PubSub.subscribe('closeLoadEvent', (message, data) => {
	console.log("Received closeLoadEvent:", data);
	if (loadingFuncs.length) {
		loadingFuncs.forEach((item) => {
			item.close()
		})
		loadingFuncs = []
	}
});

const tokenHandler = {
	refreshingToken: false,
	taskQueue: [],
	callTaskQueue() {
		this.taskQueue.forEach(callback => {
			callback();
		});
		this.taskQueue = [];
	},
};

const http = axios.create();
http.interceptors.request.use(
	config => {
		// config.data.channel = "PC";
		if (!(typeof config.disabledAuth === 'boolean' && config.disabledAuth)) {
			return new Promise((resolve) => {

				const authKey = sessionStorage.getItem('authKey');
				const hrid = store.getters.loginid;
				if (!authKey || !hrid) {

					router.replace('/login')
					return;
				}
				const setToken = () => {
					config.headers['Access-Token'] = sessionStorage.getItem('token');
					resolve(config);
				};
				const tokenExpire = sessionStorage.getItem('tokenExpire');
				// if (!tokenExpire || tokenExpire < utils.format(new Date(), 'Y-m-d H:i:s')) {
				if (!tokenExpire || Number(tokenExpire) < new Date().getTime()) {
					tokenHandler.taskQueue.push(setToken);
					if (!tokenHandler.refreshingToken) {
						tokenHandler.refreshingToken = true;
						axios
							.post('/common/getToken', {
								hrid,
								authKey,
								channel: 'PC',
							})
							.then(({
								data
							}) => {
								if (data.success) {
									// sessionStorage.setItem('tokenExpire', (data.content.expire));
									sessionStorage.setItem('tokenExpire', data.content.expireTimestamp);
									sessionStorage.setItem('token', data.content.token);
									tokenHandler.callTaskQueue();
									tokenHandler.refreshingToken = false;
								} else {
									// 清空队列
									tokenHandler.taskQueue = []
									tokenHandler.refreshingToken = false
									MessageBox.alert(data.message, i18n.t('common.alertPrompt'), {
										callback: () => {
											router.replace('/login')
										},
										type: 'error',
										center: true
									})
								}
							});
					}
				} else {
					return setToken();
				}
				return setToken;

			});
		} else {
			return config
		}
	},
	error => {
		// 对请求错误做些什么
		console.log('错误', error);
		return Promise.reject(error);
	},
);
/**
 * options
 * showLoading  是否显示加载动画
 * loadTarget 加载动画局部显示节点
 * loadTrigger auto | pubsub  是否加载完成自动关闭加载动画，也可以使用this.$pubsub.publish('closeLoadEvent')关闭加载动画
 */
export default {
	getBaseURL: () => {
		return axios.defaults.baseURL
	},
	get: (url, options) => {
		let currnetLoadingId = ''
		// if (options && ((typeof options.showLoading === 'boolean' && options.showLoading) || (typeof options.loadTarget == 'object' || typeof options.loadTarget == 'string'))) {
		if (!(options && typeof options.showLoading === 'boolean' && !options.showLoading)) {
			let loadOption = {
				lock: true,
			}
			if (options && options.loadTarget) {
				loadOption['target'] = options.loadTarget
			}
			let loadingService = Loading.service(loadOption)
			currnetLoadingId = loadingService._uid
			loadingFuncs.push(loadingService)
		}
		return new Promise((resolve, reject) => {
			http.get(url, options).then((res) => {
				resolve(res)
			}).catch((error) => {
				if (error.response) {
					if (error.response.status == 401) {
						router.replace('/login')
					} else if (error.response.status == 403) {
						MessageBox.alert(error.response.data.message, i18n.t('common.alertPrompt'), {
							callback: () => {
								router.replace('/login')
							},
							type: 'error',
							center: true
						})
					} else {
						MessageBox.alert(i18n.t('common.requestCheckConnected'), i18n.t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
						reject(error)
					}
				} else {
					if (error.code === 'ECONNABORTED') {
						MessageBox.alert(i18n.t('common.requestCheckConnected'), i18n.t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					} else {
						router.replace('/login')
						reject(error)
					}
				}
			}).finally(() => {
				if (loadingFuncs.length && !(options && typeof options.loadTrigger == 'string' && options.loadTrigger == 'pubsub')) {
					if (!(options && typeof options.showLoading === 'boolean' && !options.showLoading)) {
						const index = loadingFuncs.findIndex(item => item._uid === currnetLoadingId);
						if (index !== -1) {
							loadingFuncs[index].close();
							loadingFuncs.splice(index, 1);
						}
					}
				}
			})
		})
	},
	post: (url, data, options) => {
		let currnetLoadingId = ''
		// if (options && ((typeof options.showLoading === 'boolean' && options.showLoading) || (typeof options.loadTarget == 'object' || typeof options.loadTarget == 'string'))) {
		if (!(options && typeof options.showLoading === 'boolean' && !options.showLoading)) {
			let loadOption = {
				lock: true,
			}
			if (options && options.loadTarget) {
				loadOption['target'] = options.loadTarget
			}
			let loadingService = Loading.service(loadOption)
			currnetLoadingId = loadingService._uid
			loadingFuncs.push(loadingService)
		}
		return new Promise((resolve, reject) => {
			http.post(url, data, options).then((res) => {
				resolve(res)
			}).catch((error) => {
				if (error.response) {
					if (error.response.status == 401) {
						router.replace('/login')
					} else if (error.response.status == 403) {
						MessageBox.alert(error.response.data.message, i18n.t('common.alertPrompt'), {
							callback: () => {
								router.replace('/login')
							},
							type: 'error',
							center: true

						})

					} else {
						MessageBox.alert(i18n.t('common.requestCheckConnected'), i18n.t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
						reject(error)
					}
				} else {
					if (error.code === 'ECONNABORTED') {
						MessageBox.alert(i18n.t('common.requestCheckConnected'), i18n.t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					} else {
						router.replace('/login')
						reject(error)
					}
				}
			}).finally(() => {
				if (loadingFuncs.length && !(options && typeof options.loadTrigger == 'string' && options.loadTrigger == 'pubsub')) {
					if (!(options && typeof options.showLoading === 'boolean' && !options.showLoading)) {
						const index = loadingFuncs.findIndex(item => item._uid === currnetLoadingId);
						if (index !== -1) {
							loadingFuncs[index].close();
							loadingFuncs.splice(index, 1);
						}
					}
				}
			})
		})
	}
}
