import PubSub from 'pubsub-js';

// 回调的函数数组
let callbackFunction = []
// hash值，判断是否为当前页，不是的话清空回调函数
let hash = ''

PubSub.subscribe('langSwitchEvent', () => {
  // 之前缓存不是当前页面的, 就清空
  if (hash !== window.location.hash) {
    callbackFunction = []
  }
  callbackFunction.forEach(cb => {
    if (typeof cb === 'function') {
      cb();
    }
  });
});

export function setLanguageChangeCallback(callback) {
  if (typeof callback === 'function' || Array.isArray(callback)) {
    // 之前缓存不是当前页面的, 就清空
    if (hash !== window.location.hash) {
      callbackFunction = []
    }
    hash = window.location.hash
    if (Array.isArray(callback)) {
      callbackFunction.push(...callback)
    } else {
      callbackFunction.push(callback)
    }
  } else {
    throw new Error('Callback must be a function or an array of functions');
  }
}