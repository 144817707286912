<template>
  <div>
    <el-button icon="el-icon-play-video" v-for="(item, index) of courseList" :key="index"
      @click="openCourseVideo(item.link)">{{ item.coursename }}</el-button>
  </div>
</template>

<script>

export default {
  name: 'CourseBtn',
  props: {
    course: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      courseList: [],
      langSwitchSubscription: null
    }
  },
  mounted() {
    this.course.forEach(course => {
      this.getCourseUrl(course)
    });

    this.langSwitchSubscription = this.$pubsub.subscribe('langSwitchEvent', (message, data) => {
      console.log("Received data:", data);
      this.courseList = []
      this.course.forEach(course => {
        this.getCourseUrl(course)
      });
    });
  },
  beforeDestroy() {
    if (this.langSwitchSubscription) {
      this.$pubsub.unsubscribe(this.langSwitchSubscription);
    }

  },
  methods: {
    getCourseUrl(courseName) {
      this.$http.post('/common/courselist', {
        "hrid": this.$store.getters.loginid,
        "language": this.$store.getters.lang,
        "coursenum": courseName,
        type: 'NWEB'
      }, {
        showLoading: false
      }).then((result) => {
        if (result.data.success) {
          let data = result.data.content;
          this.courseList = this.courseList.concat(data);
        } else {
          this.$alert(result.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    openCourseVideo(url) {
      window.open(url);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
